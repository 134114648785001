import {createContext, useContext, useMemo} from 'react';
import Token from '@models/Token.model';
import {useGetTokensQuery} from '@redux/apis/TracetestCloud';

interface IContext {
  tokens: Token[];
  isLoading: boolean;
}

export const Context = createContext<IContext>({
  tokens: [],
  isLoading: true,
});

interface IProps {
  children: React.ReactNode;
}

export const useToken = () => useContext(Context);

const TokensProvider = ({children}: IProps) => {
  const {data: {items: tokens = []} = {}, isLoading} = useGetTokensQuery({
    skip: 0,
    take: 1000,
  });

  const value = useMemo<IContext>(
    () => ({
      tokens,
      isLoading,
    }),
    [isLoading, tokens]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default TokensProvider;
