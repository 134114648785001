import {COMMUNITY_SLACK_URL} from '@core/constants/Common.constants';
import * as S from './BetaBanner.styled';

const BetaBanner = () => (
  <S.Container>
    <S.Title level={3}>We are in open Beta!</S.Title>
    <S.Text>
      We welcome your{' '}
      <a target="_blank" href={COMMUNITY_SLACK_URL}>
        feedback
      </a>
    </S.Text>
  </S.Container>
);

export default BetaBanner;
