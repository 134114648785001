import {Typography} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 20px 20px 15px;
  position: relative;
`;

export const Icon = styled.div`
  font-size: 32px;
  left: 92px;
  position: absolute;
  text-align: center;
  top: -25px;
`;

export const Title = styled(Typography.Title)`
  && {
    color: ${({theme}) => theme.color.primary};
    margin: 0;
  }
`;

export const Text = styled(Typography.Text)`
  font-size: ${({theme}) => theme.size.sm};

  a {
    color: ${({theme}) => theme.color.text};
    font-weight: bold;
    text-decoration: underline;
  }
`;
