import BetaBanner from '@components/molecules/BetaBanner';
import SurveyBanner from '@components/molecules/SurveyBanner';
import UserPreferencesService from '@services/UserPreferences.service';
import {useState} from 'react';

const MenuBanner = () => {
  const [showSurveyBanner, setShowSurveyBanner] = useState(() => UserPreferencesService.get('showSurveyBanner'));
  const onSurveyClick = () => {
    UserPreferencesService.set('showSurveyBanner', false);
    setShowSurveyBanner(false);
  };

  return showSurveyBanner ? <SurveyBanner onClick={onSurveyClick} /> : <BetaBanner />;
};

export default MenuBanner;
