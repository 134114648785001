import {SURVEY_URL} from '@constants/url.constants';
import * as S from './SurveyBanner.styled';

interface IProps {
  onClick(): void;
}

const SurveyBanner = ({onClick}: IProps) => (
  <S.Container>
    <S.Icon>🎁</S.Icon>
    <S.Title level={3}>Hey, want to participate and earn cool swags?</S.Title>
    <S.Text>
      We offer awesome swag items in exchange for 5 min survey!{' '}
      <a target="_blank" href={SURVEY_URL} onClick={onClick}>
        Share Your Experience
      </a>
    </S.Text>
  </S.Container>
);

export default SurveyBanner;
