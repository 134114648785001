import {Tracetest, TracetestLogo} from '@components/atoms/Icons';
import CaptureWrapper from '@components/organisms/CaptureWrapper';
import CustomizationWrapper from '@components/organisms/CustomizationWrapper';
import HeaderMenu from '@components/organisms/HeaderMenu';
import Loading from '@components/organisms/Loading';
import LoadingLayout from '@components/organisms/LoadingLayout';
import MenuBanner from '@components/organisms/MenuBanner';
import * as S from '@core/components/Layout/Layout.styled';
import {SIDER_COLLAPSED_WIDTH, SIDER_WIDTH} from '@core/constants/Menu.constants';
import {useCheckSession} from '@hooks/authn';
import {EnvironmentsProvider} from '@providers/Environments.provider';
import {OrganizationProvider} from '@providers/Organization.provider';
import {OrganizationsProvider} from '@providers/Organizations.provider';
import {UserProvider} from '@providers/User.provider';
import {Suspense, useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import OrganizationProtectedLayoutMenu from './OrganizationProtectedLayoutMenu';

const OrganizationProtectedLayout = () => {
  const {isLoading, session} = useCheckSession('/');
  const [collapsed, setCollapsed] = useState(false);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <UserProvider session={session!}>
      <OrganizationsProvider>
        <OrganizationProvider>
          <EnvironmentsProvider>
            <CaptureWrapper>
              <CustomizationWrapper>
                <S.Layout hasSider>
                  <S.Sider
                    collapsed={collapsed}
                    collapsible
                    onCollapse={value => setCollapsed(value)}
                    width={SIDER_WIDTH}
                  >
                    <S.TopNav>
                      <Link to="/">
                        <S.Logo $collapsed={collapsed}>
                          <Tracetest />
                          <TracetestLogo />
                        </S.Logo>
                      </Link>
                    </S.TopNav>

                    <S.SiderBody>
                      <S.Menu>
                        <OrganizationProtectedLayoutMenu />
                      </S.Menu>

                      <S.MenuBottom $collapsed={collapsed}>
                        <MenuBanner />
                      </S.MenuBottom>
                    </S.SiderBody>
                  </S.Sider>

                  <S.Layout $marginLeft={collapsed ? SIDER_COLLAPSED_WIDTH : SIDER_WIDTH}>
                    <HeaderMenu />
                    <S.Body $withPadding>
                      <Suspense fallback={<LoadingLayout />}>
                        <Outlet />
                      </Suspense>
                      <S.ExtraSpace />
                    </S.Body>
                  </S.Layout>
                </S.Layout>
              </CustomizationWrapper>
            </CaptureWrapper>
          </EnvironmentsProvider>
        </OrganizationProvider>
      </OrganizationsProvider>
    </UserProvider>
  );
};

export default OrganizationProtectedLayout;
